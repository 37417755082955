/** 
 * 视听云---解决方案
 */
<template>
  <v-app>
    <v-banner
      single-line
      height="400"
      class="banner-one"
      elevation="0"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/other/audioSolationBanner.png"
          height="100%"
          :transition="false"
          class="d-flex align-center">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center align-center">
          <v-card color="transparent" flat width="80%" class="mt-16 d-flex flex-no-wrap justify-center align-center">
            <div >
              <div>
                <v-card-title class="banner-title white--text font-size-48 mb-6 pa-0 justify-center text-align-center">
                  CITV熟悉政策，了解客户心声<br/>提供最全面的解决方案
                </v-card-title>
                <div class="btnWrap">
                    <p class="banner-btn" @click="handleAppTarget()">立即申请</p>
                </div>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 根据客户需求，定制服务内容 -->
        <!-- <v-tabs-items v-model="tab1" class="mt-12" style="background-color: transparent">
          <v-tab-item v-for="(v, k) in data1.wrap2.list" :key="k"
                      :transition="false">
            <div class="d-flex justify-center">
              <transition name="slide-fade" appear>
                <v-img style="background-color: transparent"
                       max-width="400" max-height="260"
                       v-show="tab1 === k"
                       :src="v.img">
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          size="80"
                          width="8"
                          color="grey lighten-3"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </transition>
              <transition name="slide-fade-reverse" appear>
                <div style="margin-left: 80px;"
                     class="d-flex align-center"
                     v-show="tab1 === k">
                  <div>
                    <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">{{v.childrenTitle}}</v-card-title>
                    <v-card-text class="pa-0 mt-4 font-size-16 color-666">
                      <p class="mb-3" v-for="(i, j) in v.children" :key="j">{{i}}</p>
                    </v-card-text>
                  </div>
                </div>
              </transition>
            </div>
          </v-tab-item>
        </v-tabs-items> -->
    <div style="width:1200px;margin:0 auto;">
      <v-card class="warp warp2" :flat="true">
        <div class="public-title mt-12" style="margin-bottom:30px">
          <p>{{ data1.wrap2.titleEng }}</p>
          <div>
            <span>{{ data1.wrap2.title }}</span>
          </div>
        </div>
        <v-tabs v-model="tab1" centered color="#0084ff" class="g-tabs">
            <v-tab v-for="(v, k) in data1.wrap2.list" :key="k">
              <b>{{ v.tabTitle }}</b>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab1">
          <v-tab-item v-for="(v, k) in data1.wrap2.list" :key="k"
                        :transition="false">
              <div class="box">
                <transition name="slide-fade" appear>
                  <div class="left" v-show="tab1 == k"><img :src='v.img' /></div>
                </transition>
                <transition name="slide-fade-reverse" appear>
                  <div class="right" v-show="tab1 == k">
                    <v-card-text class="title" v-text="v.childrenTitle" />
                    <v-card-text class="tip" v-text="v.tip" />
                    <v-card-text class="text" v-for="(i, j) in v.children" :key="j" v-text="i" />
                  </div>
                </transition>
              </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <!-- 存储空间认证 -->
    <v-container class="warp2 wrap" style="margin:0 auto">
        <div class="public-title">
          <p>{{ data1.wrap1.titleEng }}</p>
          <div>
            <span>{{ data1.wrap1.title }}</span>
          </div>
        </div>
        <v-container class="box d-flex justify-center align-center">
            <v-card v-for="(info,index) in data1.wrap1.list" :key="index" flat>
                <div class="d-flex justify-start">
                    <v-img :src="info.src" width="400" height="260" class="flex-grow-0" v-if="index!=1"></v-img>
                    <v-img :src="info.src" width="40" height="40" class="flex-grow-0 mx-3" v-else></v-img>
                </div>
                <v-card-title class="con-title justify-center font-size-24 pa-0 my-5" v-text="info.title" ></v-card-title>
                <v-card-text class="con-text text-align-center font-size-14"> {{ info.content }} </v-card-text>
            </v-card>
        </v-container>
    </v-container>
    <!-- 企业融媒体全案 -->
    <div style="width:1200px;margin:0 auto;">
      <v-card flat class="warp3 wrap">
        <div class="public-title">
          <p>{{ data1.wrap3.titleEng}}</p>
          <div>
            <span>{{ data1.wrap3.title }}</span>
          </div>
        </div>
        <div class="con-box">
          <div class="con-item"  v-for="(info,index) in data1.wrap3.list" :key="index">
            <img :src="info.src" alt="">
            <div class="i-tit justify-start">{{  info.title  }}</div>
            <p class="con-text mb-2 text-align-left" v-for="(i, j) in info.children" :key="j">{{i}}</p>
          </div> 
          <div class="con-item1"  v-for="(info1,index) in data1.wrap3.listone" :key="index">
            <img :src="info1.src" alt="">
            <div class="i-tit1 justify-start">{{  info1.title  }}</div>
            <p class="con-text1 mb-2 text-align-left" v-for="(i, j) in info1.children" :key="j">{{i}}</p>
          </div> 
        </div>
      </v-card>
    </div>

    <!-- UGC伙伴计划 -->
    <v-container class="max-width-1200" style="margin:0 auto">
      <v-card elevation="0">
        <div class="public-title">
          <p>{{data1.wrap4.titleEng}}</p>
          <div>
            <span>{{data1.wrap4.title}}</span>
            <span>{{data1.wrap4.tip}}</span>
          </div>
        </div>
        <div class="d-flex justify-space-around mt-10">
          <div v-for="(card,index) in data1.wrap4.list" :key="index">
              <v-card  flat>
                  <v-img :src="card.src" width="100" height="100"></v-img>
                  <v-card-title class="pa-0 justify-center my-6 font-size-24" style="color:#333333" v-text="card.title"></v-card-title>
              </v-card>
          </div>
        </div>
      </v-card>
    </v-container>
    <!-- 增值服务 -->
    <v-card flat class="warp5">
      <div class="public-title">
        <p>{{ data1.wrap5.titleEng }}</p>
        <div>
          <span>{{ data1.wrap5.title }}</span>
        </div>
      </div>
      <div class="con">
        <v-row no-gutters class="justify-center mb-10">
          <v-col v-for="(info,index) in data1.wrap5.list" :key="index" cols="4" sm="3" class="d-flex justify-center" >
            <div class="box">
              <img :src='info.src' />
              <v-card-title class="justify-center font-size-20" style="color:#333333"> {{ info.title }} </v-card-title>
            </div>
          </v-col>
        </v-row> 
      </div>
    </v-card>  
  </v-app>
</template>

<script>
import contentData from '@/static/contentData.js';

export default {
  name: "audioSolution",
    data: () => ({
    data1:contentData.audioSolution,
    tab1: null,
  }),
  methods:{
      handleAppTarget(){
      const routeapp = '/audiovisualCloud/Application'
      window.open(window.location.protocol + "//" + window.location.host + routeapp)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-title{
  max-width: 850px;
}

.btnWrap .banner-btn{
  margin-top: 0px;
}
.warp2 {
  .box {
    margin: 35px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
    }
  }
  .right{
    width: 400px;
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-size: 24px !important;  
      color: #333333;
      margin-bottom: 16px;
      font-weight: 500;
      line-height: 34px;
    }
    .tip {
      font-size: 16px !important;
      line-height: 26px;
      color: #666;
    }
    .text {
      font-size: 16px;
      line-height: 30px;
      color: #666;
    }
  }

}

.warp2{
  .box{
    .con-title{
      font-size: 24px;
      color: #000;
      line-height: 26px;
      padding: 18px 0;
    }
    .con-text{
      font-size: 14px;
      color: #666666;
      line-height: 18px;
    }
  }
}
.warp3{
  .con-box{
    display: flex;
    padding-bottom: 60px;
    :nth-child(1){
      margin-right: 40px;
    }

    .con-item{
      width: 390px;
      text-align: center;
      padding: 0px 59px 0px 147px;
      margin-left: -38px;
      .i-tit{
        margin: 15px 0;
        font-size: 20px;
        color: #000000;
        line-height: 40px;
        text-align: start;
      }
      .i-text{
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        padding: 0px 20px;
      }
    }
    .con-item1{
      width: 390px;
      text-align: center;
      padding-right: 36px;
      padding-left: 100px;
      // padding: 0px 97px;
      .i-tit1{
        margin: 15px 0;
        font-size: 20px;
        color: #000000;
        line-height: 40px;
        text-align: start;
      }
      .i-text1{
        font-size: 14px;
        color: #666666;
        line-height: 20px;
        // padding: 0px 20px;
      }
    }
  }
  }
</style>